import { SuppliersQueryVariables, SupplierCardFragment } from "@graphql/types";
import classNames from "classnames";
import React, { FC, HTMLAttributes, ReactNode } from "react";
import { t } from "@helpers/translate";
import { Title } from "@components/ui/Title";
import SupplierCard from "../SupplierCard";
import SwipeCards from "@components/common/Carousel/swipeCards";

interface Props extends SuppliersQueryVariables {
  title?: string;
  title_link?: string;
  data?: SupplierCardFragment[];
  columns?: "4" | "3";
  className: HTMLAttributes<HTMLDivElement>["className"];
  children?: ReactNode;
  enableSwipeOnMobile?: boolean;
}

const SuppliersBlock: FC<Props> = ({
  title,
  data,
  children,
  columns = "4",
  title_link,
  className,
  enableSwipeOnMobile = false,
}) => {
  if (!data?.length) {
    return null;
  }

  // Check: if any supplier in the whole Block do have reviews mb-11 will be applied to all the cards with no reviews to make the height on pc an mobile for all the cards
  const hasAnyReviews = data.some(
    (node) => node.stats.rating > 0 && node.stats.reviewsCount > 0
  );

  const cards = data.map((node) => (
    <SupplierCard key={node.id} {...node} hasAnyReviews={hasAnyReviews} />
  ));

  return (
    <div className={className}>
      <div className="flex">
        <div>
          {title &&
            (title_link ? (
              <a
                title={t({
                  id: "common.link.title",
                  message: "GoWithGuide - Private Tours & Local Tour Guides",
                })}
                href={title_link}
                target="_blank"
                rel="noreferrer"
              >
                <Title as="h2" size="md" divider>
                  {title}
                </Title>
              </a>
            ) : (
              <Title as="h2" size="md" divider>
                {title}
              </Title>
            ))}
        </div>
      </div>
      {/* Mobile: If enableSwipeOnMobile is true, render SwipeCards (only visible on mobile due to block md:hidden) */}
      {enableSwipeOnMobile && (
        <div className="block md:hidden">
          <SwipeCards items={cards} slidesPerView={1.2} />
        </div>
      )}
      {/* Desktop: If enableSwipeOnMobile is true, apply "hidden md:block" to hide grid on mobile; otherwise, show grid on all screen sizes */}
      <div
        className={classNames(
          enableSwipeOnMobile ? "hidden md:grid" : "grid",
          "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4",
          {
            "lg:grid-cols-3": columns === "3",
            "lg:grid-cols-4": columns === "4",
          }
        )}
      >
        {cards}
      </div>
      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};

export default SuppliersBlock;
