import React, { HTMLAttributes, ReactNode } from "react";
import { FC } from "react";
import { CityCardFragment } from "@graphql/types";
import { Title } from "@components/ui/Title";
import LocationCard from "@components/common/LocationCard/LocationCard";
import SwipeCards from "@components/common/Carousel/swipeCards";

interface Props {
  title: string;
  data?: CityCardFragment[];
  columns?: number;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  children?: ReactNode;
  enableSwipeOnMobile?: boolean;
}

export const CitiesBlock: FC<Props> = ({
  title,
  data,
  children,
  columns = 8,
  className,
  enableSwipeOnMobile = false,
}) => {
  const grid = {
    lg: columns,
    md: Math.min(6, columns - 2),
    sm: Math.min(4, columns - 4),
    xs: 3,
  };

  const cards = data?.map((node) => <LocationCard key={node.id} {...node} />);

  if (!data?.length) {
    return null;
  }

  return (
    <div className={className}>
      <Title as="h2" size="md" divider>
        {title}
      </Title>

      {/* Mobile: If enableSwipeOnMobile is true, render SwipeCards (only visible on mobile due to block md:hidden) */}
      {enableSwipeOnMobile && cards && (
        <div className="block md:hidden">
          <SwipeCards items={cards} slidesPerView={2.4} />
        </div>
      )}
      {/* Desktop: If enableSwipeOnMobile is true, apply "hidden md:block" to hide grid on mobile; otherwise, show grid on all screen sizes */}
      <div
        className={`${
          enableSwipeOnMobile ? "hidden md:grid" : ""
        } grid gap-4 grid-cols-${grid.xs} sm:grid-cols-${grid.sm} md:grid-cols-${grid.md} lg:grid-cols-${grid.lg} `}
      >
        {cards}
      </div>

      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};
