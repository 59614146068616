import React, { ReactNode } from "react";
import { FC } from "react";
import { CountryCardFragment } from "@graphql/types";
import { Title } from "@components/ui/Title";
import LocationCard from "@components/common/LocationCard/LocationCard";
import SwipeCards from "@components/common/Carousel/swipeCards";

interface Props {
  title: string;
  data?: CountryCardFragment[];
  children?: ReactNode;
  enableSwipeOnMobile?: boolean;
}

const CountriesBlock: FC<Props> = ({
  title,
  data,
  children,
  enableSwipeOnMobile = false,
}) => {
  const cards = data?.map((node) => <LocationCard key={node.id} {...node} />);

  return (
    <div className="mb-10">
      <Title as="h2" size="md" divider>
        {title}
      </Title>
      {/* Mobile: If enableSwipeOnMobile is true, render SwipeCards (only visible on mobile due to block md:hidden) */}
      {enableSwipeOnMobile && cards && (
        <div className="block md:hidden">
          <SwipeCards items={cards} slidesPerView={2.4} />
        </div>
      )}
      {/* Desktop: If enableSwipeOnMobile is true, apply "hidden md:block" to hide grid on mobile; otherwise, show grid on all screen sizes */}
      <div
        className={`${enableSwipeOnMobile ? "hidden md:grid" : "grid"}
            grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4`}
      >
        {cards}
      </div>
      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};

export default CountriesBlock;
